import React from 'react';
import './style.css'; 

const MainPage = () => {

        // Suffix to the URL
        const handleSpanClick = (url) => {
            const fullUrl = url + "?ref=links.mahitiexplorer";
            window.open(fullUrl, "_blank");
        };

        function LinkCollection() {
    const linkData = [
        {
            id: 0,
            name: 'हेलिकॉप्टर व्हिडिओ स्पष्टीकरण',
            url:'https://bit.ly/HelicopterVideoExplainedME',
            icon:'ph-light ph-arrow-square-out'

        },
        {
            id: 1,
            name: 'माहिती एक्सप्लोरर - वेबसाईट',
            url:'https://mahitiexplorer.in/',
            icon:'ph-light ph-globe'
        },
        {
            id: 2,
            name: 'माहिती एक्सप्लोरर - इंस्टाग्राम',
            url:'https://insta.oia.bio/mahitiexplorer',
            icon:'ph-light ph-instagram-logo'
        },
        {
            id: 3,
            name: 'माहिती एक्सप्लोरर - ट्विटर',
            url:'https://bit.ly/MahitiExplorerTW',
            icon:'ph-light ph-twitter-logo'
        },
        {
            id: 4,
            name: 'माहिती एक्सप्लोरर - फेसबुक',
            url:'https://bit.ly/MahitiExplorerFB',
            icon:'ph-light ph-facebook-logo'
        },
        {
            id: 5,
            name: 'माहिती एक्सप्लोरर - यूट्यूब',
            url:'https://bit.ly/MahitiExplorerYT',
            icon:'ph-light ph-youtube-logo'

        },

    ]
    return (
        linkData.map((linkData) => (
            <div key={linkData.id} className="singleLink" onClick={() => handleSpanClick(linkData.url)}>
            <span><i class={linkData.icon}></i>{linkData.name}</span>
        </div>
        ))
    )
}

    return (
        <>
<section id="content">
        <div class="container">
            <div class="row">
                <div class="leftWrap">
                    <div class="topIntro">
                        <div class="logo">
                            <img src="ME-simpleW.png" alt="Mahiti Explorer" width="40px" />
                        </div>
                        <div class="title">
                            <h3>माहिती एक्सप्लोरर</h3>
                            <p class="username">@mahitiexplorer</p>
                        </div>
                        <div class="description">
                            <p>💻 माहिती तंत्रज्ञान | 🔎 फॅक्टस |
                                📚 सामान्य ज्ञान | ✍️साहित्य |
                                🎥 मनोरंजन | ℹ️ महत्वाची माहिती
                                आणि बरच काही आपल्या मराठी भाषेत.
                                <br />#म #मराठी
                            </p>
                        </div>
                    </div>
                    <div class="emailSec">
                        <span><a href="mailto:mahitiexplorer@gmail.com">ईमेल</a> जाहिरात आणि चौकशी</span>
                    </div>
                </div>

                <div class="linkWrap">
                    <LinkCollection />
                    
                </div>
            </div>
        </div>
        <div class="grain"></div>
    </section>

    <div class="preloader">
        <div class="loader"></div>
    </div>
    </>
    );
};

export default MainPage;
